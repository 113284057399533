import * as types from "../types/users";


const initialState = {
    users: {
        list: null,
        page: 0,
        total: 0,
        totalPages: 0
    },
	currentUser: null,
	loading: false,
	error: null,
};

export const users = (state = initialState, action) => {
    switch (action.type) {
        // LOGIN USER
        case types.GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_USER_SUCCESS:
            const list = !!state.users.list ? state.users.list : []
            return {
                ...state,
                loading: false,
                users: {
                    list: [...list, ...action.payload.data],
                    page: action.payload.page,
                    total: action.payload.total,
                    totalPages: action.payload.total_pages
                }
            };
        case types.GET_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.SET_USER:
            const newListUsers = state.users.list.map(user => user.id === action.payload.id ? action.payload : user)
            return {
                ...state,
                users: {
                    ...state.users,
                    list: newListUsers
                }
            }
        // POST BY USER
        case types.GET_POST_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_POST_USER_SUCCESS:
            const usersWithPost = state.users.list
                .map(user => user.id === action.payload.id ? ({ ...user, posts: action.payload.posts }) : (user))
            return {
                ...state,
                loading: false,
                users: {
                    ...state.users,
                    list: usersWithPost
                }
            };
        case types.GET_POST_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        // DELETE POST
        case types.DELETE_POST_USER:
            const { userId, postId } = action.payload
            const mappedUsers = state.users.list.map(user => {
                if (user.id !== userId) {
                    return user
                }
                return {
                    ...user,
                    posts: user.posts.filter(post => post.id !== postId)
                }
            })
            return {
                ...state,
                users: {
                    ...state.users,
                    list: mappedUsers
                }
            };
        // POST BY USER
        case types.GET_ALBUM_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALBUM_USER_SUCCESS:
            const usersWithAlbum = state.users.list
                .map(user => user.id === action.payload.id ? ({ ...user, album: action.payload.album }) : (user))
            return {
                ...state,
                loading: false,
                users: {
                    ...state.users,
                    list: usersWithAlbum
                }
            };
        case types.GET_ALBUM_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
