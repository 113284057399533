import React from 'react'
import TitlePage from '../../common/TitlePage'
import GridCard from '../../common/GridCard'
import useUsers from '../../../hooks/useUsers'
import MoreView from '../users/components/MoreView'
import CardUser from '../users/components/CardUser'
import Loading from '../../common/Loading'
import NoResultsFound from '../../common/NoResult'
import CollectionsIcon from '@mui/icons-material/Collections';

const AlbumTemplate = () => {

  const { page, totalPages, list, loading, error } = useUsers()

  if (!!error) {
    return <NoResultsFound/>
  }

  if (!!loading || !list) {
    return <Loading />
  }

  return (
    <div>
      <TitlePage title="Album" icon={CollectionsIcon} />
      <GridCard cards={list} component={CardUser} width="300px" />
      {(!!list && page < totalPages) && <MoreView />}
    </div>
  )
}

export default AlbumTemplate