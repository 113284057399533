import * as types from "../types/users";

const usersActions = {
    // GET LIST
    getUserRequest: (payload) => ({
        type: types.GET_USER_REQUEST,
        payload: payload,
    }),
    getUserSuccess: (payload) => ({
        type: types.GET_USER_SUCCESS,
        payload: payload,
    }),
    getUserFail: (error) => ({
        type: types.GET_USER_FAIL,
        payload: error,
    }),
    // UPDATE_USER
    setUser: (payload) => ({
        type: types.SET_USER,
        payload: payload,
    }),
    // GET POSTS BY USER
    getPostUserRequest: (payload) => ({
        type: types.GET_POST_USER_REQUEST,
        payload: payload,
    }),
    getPostUserSuccess: (payload) => ({
        type: types.GET_POST_USER_SUCCESS,
        payload: payload,
    }),
    getPostUserFail: (error) => ({
        type: types.GET_POST_USER_FAIL,
        payload: error,
    }),
    // GET ALBUM BY USER
    getAlbumUserRequest: (payload) => ({
        type: types.GET_ALBUM_USER_REQUEST,
        payload: payload,
    }),
    getAlbumUserSuccess: (payload) => ({
        type: types.GET_ALBUM_USER_SUCCESS,
        payload: payload,
    }),
    getAlbumUserFail: (error) => ({
        type: types.GET_ALBUM_USER_FAIL,
        payload: error,
    }),
    // DELETE POST
    deletePostUser: (payload) => ({
        type: types.DELETE_POST_USER,
        payload: payload,
    }),
};

export default usersActions
