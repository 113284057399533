import styled from 'styled-components';
import React from 'react';

const GridCard = (props) => {
    const { cards = [], component: Card, width = '300px' } = props;

    return (
        <GridCardWrapper width={width}>
            {cards.map((card, i) => (
                <Card key={i} {...card} />
            ))}
        </GridCardWrapper>
    );
};

export default GridCard;

const GridCardWrapper = styled.div`
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${(p) => p.width}, 1fr));
    gap: 24px;
    justify-items: center;
`;
