import { Button } from '@mui/material'
import React from 'react'
import { styled } from 'styled-components'
import CachedIcon from '@mui/icons-material/Cached';
import useUsers from '../../../../hooks/useUsers';

const MoreView = () => {

    const { handleMoreUsers } = useUsers()

    return (
        <MoreContent>
            <Button onClick={handleMoreUsers} startIcon={<CachedIcon/>}>Cargar mas usuarios</Button>
        </MoreContent>
    )
}

export default MoreView

const MoreContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`