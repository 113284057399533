import { createBrowserRouter } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import Login from "../pages/auth/Login";
import Users from "../pages/users/Users";
import PrivateRoute from "./PrivateRoute";
import Album from "../pages/album/Album";
import Logout from "../pages/auth/Logout";


const router = createBrowserRouter([
    {
        path: "/",
        element: <PublicRoute component={Login}/>,
    },
    {
        path: "/users",
        element: <PrivateRoute component={Users} />,
    },
    {
        path: "/album",
        element: <PrivateRoute component={Album} />,
    },
    {
        path: "/logout",
        element: <PrivateRoute component={Logout} />,
    },
]);

export default router