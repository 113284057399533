import * as types from "../types/auth";

const authActions = {
    loginUserRequest: (payload) => ({
        type: types.LOGIN_USER_REQUEST,
        payload: payload,
    }),
    loginUserSuccess: (payload) => ({
        type: types.LOGIN_USER_SUCCESS,
        payload: payload,
    }),
    loginUserFail: (error) => ({
        type: types.LOGIN_USER_FAIL,
        payload: error,
    }),
    logoutUser: () => ({
        type: types.LOGOUT_USER,
    })
};

export default authActions
