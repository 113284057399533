import React from 'react'
import { styled } from 'styled-components'
import Text from '../../common/Text'
import { useNavigate } from 'react-router-dom'

const ItemSidebar = (props) => {

    const { title, icon, route, handleCloseSidebar } = props

    const navigate = useNavigate()
    const { pathname } = window.location

    const handleNavigate = () => {
        handleCloseSidebar()
        navigate(route)
    }

    return (
        <Item active={pathname === route} onClick={handleNavigate}>
            {icon}
            <Text fontWeight={"bold"} color={pathname === route ? '#ffffff' : '#5e43c3'}>{title}</Text>
        </Item>
    )
}

export default ItemSidebar

const Item = styled.div`
    width: calc(100% - 72px);
    height: 50px;
    cursor: pointer;
    background-color: ${p => p.active ? '#5e43c3' : '#ffffff'};
    transition: ease-in-out 0.1s all;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 0 16px;
    border-right: 6px #5f225b00 solid;
    border-radius: 10px;
    &:hover{
        box-shadow: 2px 2px 10px 2px rgba(57,42,107,0.45);
        p, svg{
            color: ${p => !p.active ? '#5e43c3' : '#ffffff'};
            fill: ${p => !p.active ? '#5e43c3' : '#ffffff'};
        }
    }
    svg{
        fill: ${p => !p.active ? '#5e43c3' : '#ffffff'};
        font-size: 18px;
    }
`