import { IconButton } from '@mui/material'
import React from 'react'
import { styled } from 'styled-components'
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = (props) => {

    const { openSidebar, handleOpenSidebar, handleCloseSidebar } = props

    return (
        <NavbarContent>
            <div/>
            <IconButton onClick={!openSidebar ? handleOpenSidebar : handleCloseSidebar}>
                <MenuIcon/>
            </IconButton>
        </NavbarContent>
    )
}

export default Navbar

const NavbarContent = styled.nav`
    width: calc(100% - 2rem);
    height: 60px;
    box-shadow: 0px 0px 11px 0px rgba(204,204,204,0.75);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    @media (max-width: 768px) {
        height: 80px;
        box-shadow: none;
    }
`