import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
// Reducers
import { auth } from "./reducers/auth";
import { users } from "./reducers/users";

// Sagas
import { authWatcher } from "./sagas/auth";
import { usersWatcher } from "./sagas/users";



export const rootReducer = combineReducers({
	auth,
	users
});

export function* rootSaga() {
	yield all([
		authWatcher(),
		usersWatcher()
	]);
}
