import React, { useState } from 'react'
import { styled } from 'styled-components'
import TitlePage from '../../../common/TitlePage'
import usePostByUser from '../../../../hooks/usePostByUser'
import Post from './Post'
import Loading from '../../../common/Loading'

const PostsUser = (props) => {

    const { id } = props
    const { posts } = usePostByUser(id, 'posts')
    const [active, setActive] = useState(false)

    const handleActive = (id) => setActive(id)

    if (!posts) {
        return <Loading />
    }

    return (
        <PostContent>
            <TitlePage title="Posts" />
            <Posts>
                {posts.map(post => <Post key={post.id} userId={id} active={active} handleActive={handleActive} {...post}/>)}
            </Posts>
        </PostContent>
    )
}

export default PostsUser

const PostContent = styled.section`
    padding: 0 18px;
    height: 100%;
`

const Posts = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 50%;
    overflow-y: auto;
    gap: 12px;
    padding: 6px 0;
    @media (max-width: 768px) {
        max-height: 60%;
    }
`