import React, { useState } from 'react'
import { Button, Popover, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Text from '../../../common/Text';
import { styled } from 'styled-components';
import useYupValidationResolver from '../../../../hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { userSchema } from '../../../../utils/schemes/user.scheme';
import { useDispatch } from 'react-redux';
import usersActions from '../../../../redux/actions/users';

const EditUser = (props) => {

    const { id, firstName, lastName, email, avatar } = props

    const defaultValues = {
        first_name: firstName,
        last_name: lastName,
        email
    };

    const dispatch = useDispatch()
    const resolver = useYupValidationResolver(userSchema);
    const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues, resolver });
    const onSubmit = body => {
        dispatch(usersActions.setUser({ id, avatar, ...body }))
        handleClose()
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const idPop = open ? 'simple-popover' : undefined;


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button startIcon={<EditIcon />} onClick={handleClick}>Editar</Button>
            <Popover
                id={idPop}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Content>
                    <Text fontSize={"20px"} color={"#8053ff"}>Editar a {firstName}</Text>
                    <FormContent onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            size='small'
                            error={!!errors.first_name}
                            helperText={errors?.first_name?.message}
                            {...register("first_name")}
                        />
                        <TextField
                            size='small'
                            error={!!errors.last_name}
                            helperText={errors?.last_name?.message}
                            {...register("last_name")}
                        />
                        <TextField
                            size='small'
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                            {...register("email")}
                        />
                        <Buttons>
                            {<Button onClick={handleClose}>Cancelar</Button>}
                            <Button type='submit' disabled={false} onClick={handleSubmit} variant='contained' color='primary'>{!false ? 'Aplicar' : 'Guardando'}</Button>
                        </Buttons>
                    </FormContent>

                </Content>
            </Popover>
        </>
    )
}

export default EditUser

const Content = styled.div`
    width: 350px;
    height: auto;
    padding: 18px 18px 0 18px;
    @media (max-width: 768px) {
        width:  calc(90vw - 30px);
    }
`

const FormContent = styled.form`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 0;
`

const Buttons = styled.div`
    border-top: 1px solid #8053ff22;
    margin-top: 1rem;
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
`