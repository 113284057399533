import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { reduxBatch } from "@manaflair/redux-batch";
import { persistStore } from "redux-persist";
import { rootReducer, rootSaga } from "./rootReducer";

const sagaMiddleware = createSagaMiddleware();
const middleware = [
    sagaMiddleware
];

export const store = configureStore({
    reducer: rootReducer,
    middleware,
    enhancers: [reduxBatch]
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

