import { useState } from 'react'

const useSnackbar = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(null)

    const openSnackbar = (message) => {
        setMessage(message)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return [ open, message, openSnackbar, handleClose ]
}

export default useSnackbar