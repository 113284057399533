import React from 'react'
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { styled } from 'styled-components';

const TitlePage = (props) => {

    const { title, icon: Icon = false } = props

    return (
        <TitleContent>
            <Data>
                {Icon ? <Icon/> : <TurnedInIcon/>}
                <p>{ title }</p>
            </Data>
            <Divider/>
        </TitleContent>
    )
}

export default TitlePage

const TitleContent = styled.div`
    display: flex;
    flex-direction: column;
`

const Data = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 18px;
    font-weight: 700;
    color: #5e43c3;
    padding-bottom: 8px;
`

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: rgb(94,67,195);
    background: linear-gradient(90deg, rgba(94,67,195,1) 0%, rgba(246,246,246,1) 100%);
`