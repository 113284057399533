import React from 'react'
import { styled } from 'styled-components'
import { PropTypes } from "prop-types"

const Image = (props) => {

    const { src, alt = '', width, height } = props

    return <Img src={src} alt={alt} w={width} h={height} />
}

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default Image

const Img = styled.img`
    width: ${p => p.w};
    height: ${p => p.h};
`