import axios from "axios";

// const URI = process.env.REACT_APP_RE
const URI_RE = "https://reqres.in/api"
const URI_JT = "https://jsonplaceholder.typicode.com"

export function getUsersAPI(page) {
	return axios.get(`${URI_RE}/users?page=${page}`);
}

export function getPostByUserAPI(userId) {
	return axios.get(`${URI_JT}/posts?userId=${userId}`);
}

export function getAlbumByUserAPI(userId) {
	// No se puede usar user id, por ende solo se cambia el id de album
	return axios.get(`${URI_JT}/photos?_start=0&_limit=16&albumId=${userId}`);
}