import React from 'react'
import FormLogin from './components/FormLogin';
import { styled } from 'styled-components';

const LoginTemplate = () => {
    return (
        <LoginContent>
            <FormLogin/>
        </LoginContent>
    )
}

export default LoginTemplate


const LoginContent = styled.main`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
`