import * as yup from "yup";

export const loginSchema = yup.object({
    email: yup
        .string()
        .email("Formato inválido")
        .required("Correo electrónico requerido")
        .max(32, "Máximo de 32 dígitos")
        .min(5, "Mínimo de 5 dígitos"),
    password: yup
        .string()
        .required("Contraseña requerida")
        .max(16, "Máximo de 16 dígitos")
        .min(5, "Mínimo de 5 dígitos")
});