import { IconButton } from '@mui/material'
import React from 'react'
import { styled } from 'styled-components'
import GroupIcon from '@mui/icons-material/Group';
import CollectionsIcon from '@mui/icons-material/Collections';
import ItemSidebar from './ItemSidebar';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import UserSidebar from './UserSidebar';

const Sidebar = (props) => {

    const { openSidebar, handleCloseSidebar } = props

    return (
        <SidebarContent isOpen={openSidebar}>
            <SidebarView>
                <Header>
                    <UserSidebar/>
                    <CloseButton onClick={handleCloseSidebar}>
                        <KeyboardArrowLeftIcon/>
                    </CloseButton>
                </Header>
                <Body>
                    <ItemSidebar title={'Usuarios'} icon={<GroupIcon />} route='/users' handleCloseSidebar={handleCloseSidebar} />
                    <ItemSidebar title={'Album'} icon={<CollectionsIcon />} route='/album' handleCloseSidebar={handleCloseSidebar}/>
                </Body>
            </SidebarView>
            <SidebarTransparent onClick={handleCloseSidebar}/>
        </SidebarContent>
    )
}

export default Sidebar

const SidebarContent = styled.div`
    position: fixed;
    height: 100%;
    left: ${p => p.isOpen ? '0' : '-100%'};
    top: 0;
    z-index: 9999;
    transition: ease-in-out all 0.3s;
    display: flex;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const SidebarView = styled.div`
    position: static;
    width: 250px;
    background-color: #fff;
    box-shadow: 8px 0px 8px 0px rgba(204,204,204,0.75);
    z-index: 2;
    @media (max-width: 768px) {
        width: 250px;
        left: ${p => p.isOpen ? '0' : '-250px'};
    }
`

const SidebarTransparent = styled.div`
    width: 0;
    @media (max-width: 768px) {
        display: flex;
        width: 40%;
        left: ${p => p.isOpen ? '0' : '-40%'};
    }
`

const Header = styled.aside`
    height: 80px;
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
    border: 1px solid #f1f1f1;
    svg{
        fill: #fff;
    }
`

const Body = styled.div`
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    height: calc(100vh - 80px - 120px);
`

const CloseButton = styled(IconButton)`
    position: absolute;
    right: -35px;
    background-color: #5e43c3 !important;
    box-shadow: 8px 0px 8px 0px rgba(204,204,204,0.75);
    svg{
        fill: #fff !important;
    }
`