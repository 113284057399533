import * as yup from "yup";

export const userSchema = yup.object({
    email: yup
        .string()
        .email("Formato inválido")
        .required("Correo electrónico requerido")
        .max(32, "Máximo de 32 dígitos")
        .min(5, "Mínimo de 5 dígitos"),
    first_name: yup
        .string()
        .required("Apellido requerido")
        .max(32, "Máximo de 32 dígitos")
        .min(2, "Mínimo de 2 dígitos"),
    last_name: yup
        .string()
        .required("Nombre requerido")
        .max(32, "Máximo de 32 dígitos")
        .min(2, "Mínimo de 2 dígitos")
});