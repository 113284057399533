import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import authActions from '../../redux/actions/auth'
import Loading from '../../components/common/Loading'

const Logout = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(authActions.logoutUser())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Loading />
    )
}

export default Logout