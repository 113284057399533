import { CircularProgress } from '@mui/material'
import React from 'react'
import { styled } from 'styled-components'

const Loading = () => {
    return (
        <LoadContent>
            <CircularProgress />
        </LoadContent>
    )
}

export default Loading

const LoadContent = styled.div`
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`