import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usersActions from '../redux/actions/users'

const usePostByUser = (idUser = false, typeSearch = false) => {

    const { list } = useSelector(state => state.users.users)
    const [posts, setPosts] = useState(null)
    const [album, setAlbum] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        typeSearch === 'posts' && !!idUser && !!list && getPostsUser(idUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posts, list])

    useEffect(() => {
        typeSearch === 'album' && !!idUser && !!list && getAlbumUser(idUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [album, list])

    const getPostsUser = (idUser) => {
        const postsUser = list.find(user => user.id === idUser && user.posts)
        if (!postsUser) {
            dispatch(usersActions.getPostUserRequest(idUser))
        }
        else {
            setPosts(postsUser.posts)
        }
    }
    
    const handleDeletePost = (postId, userId) => {
        dispatch(usersActions.deletePostUser({ postId, userId }))
    }

    const getAlbumUser = (idUser) => {
        const albumUser = list.find(user => user.id === idUser && user.album)
        if (!albumUser) {
            dispatch(usersActions.getAlbumUserRequest(idUser))
        }
        else {
            setAlbum(albumUser.album)
        }
    }

    return { posts, handleDeletePost, album }
}

export default usePostByUser