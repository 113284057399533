import React, { useState } from 'react'
import { styled } from 'styled-components'
import Text from '../../../common/Text'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import SideBarUser from './SideBarUser';
import { Avatar } from './Avatar';
import SideBarAlbum from '../../album/components/SideBarAlbum';


const CardUser = (props) => {

    const {
        id = false,
        avatar = 'https://reqres.in/img/faces/1-image.jpg',
        first_name = '',
        last_name = '',
        email = '',
    } = props

    const [open, setOpen] = useState(false)
    const { pathname } = window.location

    const handleOpenSidebar = () => {
        setOpen(true)
    }

    const handleClose = () => setOpen(false)

    return (
        <>
            <CardContent key={id} onClick={handleOpenSidebar}>
                <Avatar avatar={avatar} />
                <Information>
                    <div>
                        <Text fontSize={"12px"} color={"#666666"}>{pathname === '/users' ? '#información' : '#album'}</Text>
                        <Text color={"#333333"} fontWeight={"bold"}>{`${first_name} ${last_name}`}</Text>
                        <Text fontSize={"12px"} color={"#666666"}>{email}</Text>
                    </div>
                    <IconButtonFloat onClick={handleOpenSidebar}>
                        <EditIcon />
                    </IconButtonFloat>
                </Information>

            </CardContent>
            {pathname === '/users' && <SideBarUser open={open} handleClose={handleClose} {...props} />}
            {pathname === '/album' && <SideBarAlbum open={open} handleClose={handleClose} {...props} />}
        </>
    )
}

export default CardUser

const CardContent = styled.article`
    width: 100%;
    height: 100px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
`

const Information = styled.div`
    width: calc(100% - 110px);
    height: calc(72px - 24px);
    display: flex;
    justify-content: space-around;
    background-color: green;
    border-radius: 20px;
    position: absolute;
    left: 80px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 11px 0px rgba(204,204,204,0.75);
    padding: 12px 0;
    padding-left: 30px;
    transition: ease-in-out .3s all;
    &:hover{
        box-shadow: 0px 0px 11px 0px rgba(158, 136, 242,0.75);
    }
`

const IconButtonFloat = styled(IconButton)`
    position: absolute;
    right: 5px;
    z-index: 2;
    svg{
        fill: #aaa;
    }
`