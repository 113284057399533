import React, { useState } from 'react'
import { styled } from 'styled-components'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
// import Menu from './components/Menu'


const Layout = (props) => {

    const { children } = props

    const [openSidebar, setOpenSidebar] = useState(false)

    const handleOpenSidebar = () => setOpenSidebar(true)
    const handleCloseSidebar = () => setOpenSidebar(false)

    return (
        <>
            <Navbar openSidebar={openSidebar} handleOpenSidebar={handleOpenSidebar} handleCloseSidebar={handleCloseSidebar} />
            <Sidebar openSidebar={openSidebar} handleCloseSidebar={handleCloseSidebar} />
            <Main isOpen={openSidebar}>
                {/* <Menu/> */}
                { children }
            </Main>
        </>
    )
}

export default Layout

const Main = styled.main`
    width: ${p => p.isOpen ? 'calc(100% - 250px - 48px)' : 'calc(100% - 48px)'};
    padding: 24px;
    position: relative;
    left: ${p => p.isOpen ? '250px' : '0'};
    transition: ease-in-out all .4s;
    border-radius: 0;
    height: calc(100vh - 60px);
    @media (max-width: 768px) {
        width: calc(100% - 48px);
        position: static;
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        box-shadow: 0px 0px 11px 0px rgba(204,204,204,0.75);
        background-color: #f6f6f6;
    }
`