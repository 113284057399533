import React from 'react'
import { styled } from 'styled-components'
import Text from '../../../common/Text'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import usePostByUser from '../../../../hooks/usePostByUser';

const Post = (props) => {

    const { id, userId, title, body, active, handleActive } = props

    const isActive = active === id

    const { handleDeletePost } = usePostByUser()

    return (
        <PostContent active={isActive} onClick={() => handleActive(id)}>
            <TitleSection>
                <Text fontSize={"12px"} color={isActive ? '#5e43c3' : '#ccc'}>#POST - {id}</Text>
                <Text fontSize={"14px"} color={"#333"}>{title}</Text>
                {isActive && <Description>
                    { body }
                </Description>}
            </TitleSection>
            {isActive && <Actions>
                <IconButton size='small' onClick={() => handleDeletePost(id, userId)}>
                    <DeleteIcon fontSize='small'  />
                </IconButton>
            </Actions>}
        </PostContent>
    )
}

export default Post

const PostContent = styled.article`
    width: calc(100% - 2px);
    height: auto;
    box-shadow: 1px 1px 1px 1px ${p => p.active ? '#5e43c355' : '#eee'};
    border-radius: 5px;
    display: grid;
    position: relative;
    margin-top: ${p => p.active ? '15px' : '0'};
    transition: ease-in-out .3s all;
    user-select: none;
    cursor: pointer;
    &:hover{
        box-shadow: 1px 1px 1px 1px #5e43c355;
    }
`

const TitleSection = styled.div`
    padding: 6px;
`

const Description = styled.p`
    border-top: 1px solid #aaa !important;
    color: #777;
    margin-top: 8px;
    font-size: 12px;
    padding-top: 6px;
`

const Actions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -15px;
    right: 5px;
    button{
        background-color: #5e43c3;
        color: #fff;
        &:hover{
            background-color: #5e43c3;
            color: #fff;
        }
    }
`