import * as types from "../types/auth";
import { call, put, takeEvery } from "redux-saga/effects";
import { loginUserAPI } from "../api/auth";
import authActions from "../actions/auth";


function* loginRequest(action) {
    const { loginUserSuccess, loginUserFail } = authActions
	try {
		const res = yield call(loginUserAPI, action.payload);
		yield put(
			loginUserSuccess({...res.data, email: action.payload.email})
		);
	} catch (error) {
		yield put(loginUserFail(error));
	}
}


function* authWatcher() {
	yield takeEvery(types.LOGIN_USER_REQUEST, loginRequest);
}
export { authWatcher };
