import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Button, IconButton, InputAdornment, Snackbar, TextField } from '@mui/material';
import Text from '../../../../common/Text';
import login from "../../../../../assets/images/login.svg";
import Image from '../../../../common/Image';
import { useForm } from 'react-hook-form';
import useYupValidationResolver from '../../../../../hooks/useYupValidationResolver';
import { loginSchema } from '../../../../../utils/schemes/auth.scheme';
import { useDispatch, useSelector } from 'react-redux';
import authActions from '../../../../../redux/actions/auth';
import useSnackbar from '../../../../../hooks/useSnackbar';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const FormLogin = () => {

    const defaultValues = {
        email: null,
        password: null
    };

    const dispatch = useDispatch()
    const { loading, error } = useSelector(state => state.auth)
    const [open, message, openSnackbar, handleClose] = useSnackbar()
    const resolver = useYupValidationResolver(loginSchema);
    const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues, resolver });
    const onSubmit = body => {
        dispatch(authActions.loginUserRequest(body))
    };
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!!error && !loading) {
            openSnackbar("Ocurrió un error al iniciar sesión")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    return (
        <FormCard>
            <Banner>
                <Image width={"150px"} src={login} />
                <Text color={"#FFF"} fontWeight={"bold"} fontSize={"18px"}>Web System</Text>
            </Banner>
            <FormContent onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Text fontSize={"25px"} fontWeight={"bold"} color={'#5e43c3'}>Iniciar sesión</Text>
                    <Text fontSize={"14px"} color={'#626262'}>Por favor ingresar credenciales</Text>
                </div>
                <TextField
                    label="Usuario"
                    size='small'
                    fullWidth
                    variant='standard'
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register("email")}
                />
                <TextField
                    label="Contraseña"
                    size='small'
                    fullWidth
                    variant='standard'
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={toggleShowPassword}
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...register("password")}
                />
                <ButtonWrapper>
                    <ButtonLogin disabled={loading} type="submit" color='primary' variant='contained'>{!loading ? "INICIAR SESIÓN" : "INICIANDO"}</ButtonLogin>
                </ButtonWrapper>
            </FormContent>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
            />
        </FormCard>
    )
}

export default FormLogin

const FormCard = styled.div`
    background-color: #5e43c3;
    width: 400px;
    height: 80vh;
    box-shadow: 0px 0px 29px 0px rgba(140,140,140,0.75);
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    @media (max-width: 768px) {
        width: 100%;
        height: 100vh;
        border-radius: 0;
    }
`

const FormContent = styled.form`
    padding: 42px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(80vh - 200px);
    gap: 12px;
    background-color: #fff;
    border-radius: 50px;
    @media (max-width: 768px) {
        justify-content: center;
        gap: 24px;
        height: 50vh;
        border-radius: 0;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
    }
`

const Banner = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        height: 50vh;
    }
`

const ButtonLogin = styled(Button)`
    box-shadow: 15px 17px 101px -31px rgba(94,67,195,0.83) !important;
    font-size: 13px !important;
    width: 180px;
    padding: 8px 32px !important;
`

const ButtonWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 18px;
`