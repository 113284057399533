import React from 'react'
import { styled } from 'styled-components'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar } from './Avatar';
import Text from '../../../common/Text';
import EditUser from './EditUser';
import PostsUser from './PostsUser';

const SideBarUser = (props) => {

    const {
        id,
        open,
        handleClose,
        avatar = '',
        first_name,
        last_name,
        email
    } = props

    return (
        <>
            <EmptySpacing onClick={handleClose} open={open}/>
            <SidebarContent open={open}>
                <CloseButton onClick={handleClose}>
                    <KeyboardArrowDownIcon />
                </CloseButton>
                <User>
                    <Avatar avatar={avatar} />
                    <div>
                        <EditUser id={id} firstName={first_name} lastName={last_name} email={email} avatar={avatar} />
                        <Text fontSize={"24px"} color={"#333333"} fontWeight={"bold"}>{`${first_name} ${last_name}`}</Text>
                        <Text fontSize={"18px"} color={"#666666"}>{email}</Text>
                    </div>
                </User>
                {open && <PostsUser id={id} />}
            </SidebarContent>
        </>

    )
}

export default SideBarUser

const SidebarContent = styled.aside`
    height: 80%;
    z-index: 99;
    width: 40%;
    position: fixed;
    left: calc(50% - 20%);
    bottom: ${p => p.open ? '0%' : '-100%'};
    border-radius: 0;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    background-color: white;
    box-shadow: 4px 3px 27px 3px rgba(74,74,74,0.45);
    transition: ease-in-out .3s all;
    @media (max-width: 768px) {
        bottom: ${p => p.open ? '0' : '-100%'};
        left: calc(50% - 225px);
        height: 70%;
        width: 100%;
        height: 85%;
        left: 0;
    }
`

const EmptySpacing = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000022;
    z-index: 9;
    transition: ease-in-out 1s all;
    display: ${p => p.open ? 'block' : 'none'};
`

const CloseButton = styled.button`
    background-color: #00000000;
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    border: none;
    cursor: pointer;
    transition: ease-in-out .3s all;
    svg{
        transition: ease-in-out .3s all;
        fill: #666;
    }
    &:hover{
        svg{
            padding-top: 8px;
            fill: #5e43c3;
        }
    }
`

const User = styled.article`
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 18px;
`