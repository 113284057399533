import React from 'react'
import { styled } from 'styled-components'
import TitlePage from '../../../common/TitlePage'
import usePostByUser from '../../../../hooks/usePostByUser'
import Image from '../../../common/Image'

const AlbumUser = (props) => {

    const { id, firstName } = props
    const { album } = usePostByUser(id, 'album')

    return (
        <AlbumContent>
            <TitlePage title={`Album de ${firstName}`} />
            {!!album && <Gallery>
                {album.map(itemAlbum => <Image width={"120px"} src={itemAlbum.url} alt={itemAlbum.title} />)}
            </Gallery>}
        </AlbumContent>
    )
}

export default AlbumUser

const AlbumContent = styled.section`
    padding: 0 18px;
    height: 100%;
`

const Gallery = styled.div`
    display: grid;
    max-height: 75%;
    overflow-y: auto;
    gap: 6px;
    padding: 12px 0;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-items: center;
    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        max-height: 75%;
    }
`