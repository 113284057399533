import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import Loading from "../components/common/Loading";


const PublicRoute = (props) => {
    const { component: Component, isAuth } = props;

    const navigate = useNavigate()

    useEffect(() => {
        isAuth && navigate('/users')
    }, [isAuth, navigate])

    if (!!isAuth) {
        return <Loading/>
    }    

    return (
        <Component {...props} />
    );
};

PublicRoute.propTypes = {
    component: PropTypes.any,
    isAuth: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isAuth: !!state.auth.authToken,
});

export default connect(mapStateToProps)(PublicRoute);
