import { styled } from "styled-components";

export const Avatar = styled.div`
    width: 100px;
    min-width: 100px;
    height: 100px;
    background-color: #FFFFFF;
    border-radius: 20px;
    z-index: 2;
    box-shadow: 4px 3px 27px 3px rgba(74,74,74,0.45);
    background-image: url(${p => p.avatar});
    background-repeat: no-repeat;
    background-position: center;
`