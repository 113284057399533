import * as types from "../types/users";
import { call, put, takeEvery } from "redux-saga/effects";
import usersActions from "../actions/users";
import { getUsersAPI, getPostByUserAPI, getAlbumByUserAPI } from "../api/users";


function* usersRequest(action) {
    const { getUserSuccess, getUserFail } = usersActions
	try {
		const res = yield call(getUsersAPI, action.payload);
		yield put(
			getUserSuccess(res.data)
		);
	} catch (error) {
		yield put(getUserFail(error));
	}
}

function* postByUserRequest(action) {
    const { getPostUserSuccess, getPostUserFail } = usersActions
	try {
		const res = yield call(getPostByUserAPI, action.payload);
		yield put(
			getPostUserSuccess({ id: action.payload, posts: res.data})
		);
	} catch (error) {
		yield put(getPostUserFail(error));
	}
}

function* albumByUserRequest(action) {
    const { getAlbumUserSuccess, getAlbumUserFail } = usersActions
	try {
		const res = yield call(getAlbumByUserAPI, action.payload);
		yield put(
			getAlbumUserSuccess({ id: action.payload, album: res.data})
		);
	} catch (error) {
		yield put(getAlbumUserFail(error));
	}
}

function* usersWatcher() {
	yield takeEvery(types.GET_USER_REQUEST, usersRequest);
	yield takeEvery(types.GET_POST_USER_REQUEST, postByUserRequest);
	yield takeEvery(types.GET_ALBUM_USER_REQUEST, albumByUserRequest);
}
export { usersWatcher };
