import React from 'react'
import { useSelector } from 'react-redux'
import { styled } from 'styled-components'
import Text from '../../common/Text'
import { Link } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout';

const UserSidebar = () => {

    const { user } = useSelector(state => state.auth)

    return (
        <UserContent>
            <AvatarLetter>{user[0]?.toUpperCase()}</AvatarLetter>
            <Information>
                <Text fontSize={"14px"} fontWeight={"bold"}>{user}</Text>
                <LinkLogout to={'/logout'}><LogoutIcon/>Cerrar sesión</LinkLogout>
            </Information>
        </UserContent>
    )
}

export default UserSidebar

const UserContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`

const Information = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 5px;
`

const AvatarLetter = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: #5e43c3;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
`

const LinkLogout = styled(Link)`
    color: #5e43c3;
    text-decoration: none;
    font-size: 12px !important;
    display: flex;
    gap: 6px;
    align-items: center;
    svg{
        fill: #5e43c3;
        font-size: 12px;
    }
`