import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usersActions from '../redux/actions/users'

const useUsers = () => {

    const { page, totalPages, list, loading, error } = useSelector(state => state.users.users)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!list && !loading) {
            dispatch(usersActions.getUserRequest(1))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleMoreUsers = () => {
        const currentPage = page + 1
        if (currentPage <= totalPages) {
            dispatch(usersActions.getUserRequest(page + 1))
        }
    }
    

    return { page, totalPages, list, loading, error, handleMoreUsers }
}

export default useUsers