import React from 'react'
import { styled } from 'styled-components'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AlbumUser from './AlbumUser';

const SideBarAlbum = (props) => {

    const {
        id,
        first_name,
        open,
        handleClose,
    } = props

    return (
        <>
            <EmptySpacing onClick={handleClose} open={open}/>
            <SidebarContent open={open}>
                <CloseButton onClick={handleClose}>
                    <KeyboardArrowDownIcon />
                </CloseButton>
                {open && <AlbumUser id={id} firstName={first_name} />}
            </SidebarContent>
        </>

    )
}

export default SideBarAlbum

const SidebarContent = styled.aside`
    height: 80%;
    z-index: 99;
    width: 40%;
    position: fixed;
    left: calc(50% - 20%);
    bottom: ${p => p.open ? '0%' : '-100%'};
    border-radius: 0;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    background-color: white;
    box-shadow: 4px 3px 27px 3px rgba(74,74,74,0.45);
    transition: ease-in-out .3s all;
    @media (max-width: 768px) {
        bottom: ${p => p.open ? '0' : '-100%'};
        left: calc(50% - 225px);
        height: 70%;
        border-radius: 0;
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        width: 100%;
        height: 85%;
        left: 0;
    }
`

const EmptySpacing = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000022;
    z-index: 9;
    transition: ease-in-out 1s all;
    display: ${p => p.open ? 'block' : 'none'};
`

const CloseButton = styled.button`
    background-color: #00000000;
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    border: none;
    cursor: pointer;
    transition: ease-in-out .3s all;
    svg{
        transition: ease-in-out .3s all;
        fill: #666;
    }
    &:hover{
        svg{
            padding-top: 8px;
            fill: #5e43c3;
        }
    }
`