import styled from "styled-components";
import { PropTypes } from "prop-types"

const Text = (props) => {

    const { fontSize, fontWeight, color, textAlign, children } = props

    return <TextContent fontSize={fontSize} fontWeight={fontWeight} color={color} textAlign={textAlign}>
        {children}
    </TextContent>
}

export default Text;

Text.propTypes = {
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.string,
    textAlign: PropTypes.string
};

const TextContent = styled.p`
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight};
    color: ${p => p.color};
    text-align: ${p => p.textAlign};
`
