// GET_ALL
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
// SET_USER
export const SET_USER = "SET_USER";
// GET_POST_USER
export const GET_POST_USER_REQUEST = "GET_POST_USER_REQUEST";
export const GET_POST_USER_SUCCESS = "GET_POST_USER_SUCCESS";
export const GET_POST_USER_FAIL = "GET_POST_USER_FAIL";
// GET_ALBUM_USER
export const GET_ALBUM_USER_REQUEST = "GET_ALBUM_USER_REQUEST";
export const GET_ALBUM_USER_SUCCESS = "GET_ALBUM_USER_SUCCESS";
export const GET_ALBUM_USER_FAIL = "GET_ALBUM_USER_FAIL";
// DELETE_POST
export const DELETE_POST_USER = "DELETE_POST_USER";